






























































































































import Vue from 'vue';
import { ImageLabel, ImageTestSet, TestResult } from '@/views/types';
import axios from 'axios';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'MarkImageLabel',
  components: {},
  data() {
    let imageTestSets = Array<ImageTestSet>();
    let targetImageTestSet: ImageTestSet = {
      _id: '',
      topic: '',
      createDate: new Date(),
      images: Array<string>(),
      labels: Array<ImageLabel>(),
      testResults: Array<TestResult>(),
    };
    const llmOptions = [
      { text: 'ChatGPT 4-o', value: 'gpt-4o' },
      { text: 'ChatGPT 4-o-mini', value: 'gpt-4o-mini' },
    ];
    const columns = [
      {
        key: 'id',
        label: '圖片',
      },
      {
        key: 'label',
        label: '標記',
      },
    ];
    return {
      imageTestSets,
      targetImageTestSet,
      columns,
      currentPage: 1,
      llmOptions,
      form: {
        model: 'gpt-4o-mini',
        question: '',
        keyword: '',
      },
    };
  },
  computed: {
    labels() {
      switch (this.targetImageTestSet.topic) {
        case '煙囪排放':
          return ['黑煙', '白煙', '沒有', '無法辨識'];

        default:
          return ['有', '無', '無法辨識'];
      }
    },
    myColumns() {
      let ret = [];
      ret.push(
        {
          key: 'id',
          label: '圖片',
        },
        {
          key: 'label',
          label: '標記',
        },
      );

      if (
        this.targetImageTestSet.labels &&
        this.targetImageTestSet.testResults
      ) {
        for (let i = 0; i < this.targetImageTestSet.testResults.length; i++) {
          let testResult = this.targetImageTestSet.testResults[i];
          ret.push({
            key: `result_${i}`,
            label: `${testResult.model} - ${testResult.question}`,
            model: testResult.model,
            question: testResult.question,
            keyword: testResult.keyword,
          });
        }
      }
      return ret;
    },
    myRows() {
      let ret = [];
      for (let i = 0; i < this.targetImageTestSet.labels.length; i++) {
        let label = this.targetImageTestSet.labels[i];
        let row: any = {
          id: label.id,
          label: label.label,
        };
        if (this.targetImageTestSet.testResults) {
          for (let j = 0; j < this.targetImageTestSet.testResults.length; j++) {
            let testResult = this.targetImageTestSet.testResults[j];
            row[`result_${j}`] = {
              label: testResult.response[i].label,
              correct: this.isTestCorrect(
                label.label === '有',
                testResult.response[i].label,
                testResult.keyword,
              ),
              keyword: testResult.keyword,
              trigger: label.label === '有',
            };
          }
        }
        ret.push(row);
      }
      return ret;
    },
  },
  watch: {
    targetImageTestSet() {
      this.initLabels();
    },
  },
  async created() {
    await this.getImageTestSets();
  },
  methods: {
    ...mapMutations(['setLoading']),
    async getImageTestSets() {
      try {
        const res = await axios.get('/ImageTestSet');
        if (res.status === 200) {
          this.imageTestSets = res.data;
          this.assignTargetImageTestSet(0);
        }
      } catch (e) {
        console.error(e);
      }
    },
    assignTargetImageTestSet(index: number) {
      this.targetImageTestSet = this.imageTestSets[index];
      this.initLabels();
    },
    initLabels() {
      if (this.targetImageTestSet.labels.length == 0) {
        this.targetImageTestSet.labels = this.targetImageTestSet.images.map(
          image => {
            return {
              id: image,
              label: '',
            };
          },
        );
      }
    },
    getImageUrl(id: string) {
      if (!id) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${id}`;
    },
    async executeTest() {
      try {
        this.setLoading({ loading: true, message: 'AI 推理中' });
        const res = await axios.post(
          `/ImageTestSet/${this.targetImageTestSet._id}/ExecuteTest`,
          this.form,
        );
        if (res.status === 200) {
          this.$bvModal.msgBoxOk('執行成功');
          this.getImageTestSets();
          this.$emit('reload');
        }
      } catch (e) {
        console.error(e);
        this.$bvModal.msgBoxOk('執行失敗');
      } finally {
        this.setLoading({ loading: false });
      }
    },
    isTestCorrect(
      label: boolean,
      response: string,
      keywordStr?: string,
    ): boolean {
      let keywords = keywordStr ? keywordStr.split(',') : [];
      let event = false;
      for (let keyword of keywords) {
        if (response.indexOf(keyword) !== -1) {
          event = true;
          break;
        }
      }
      if (label && event) return true;

      if (!label && !event) return true;

      return false;
    },
    getFooter(data: any) {
      if (data.column === 'id') {
        return '準確率';
      }
      if (data.column === 'label') {
        return '-';
      }
      let index = parseInt(data.column.replace('result_', ''));
      let testResult = this.targetImageTestSet.testResults[index];
      let correct = 0;
      let total = testResult.response.length;
      for (let i = 0; i < testResult.response.length; i++) {
        if (
          this.isTestCorrect(
            this.targetImageTestSet.labels[i].label === '有',
            testResult.response[i].label,
            testResult.keyword,
          )
        )
          correct++;
      }
      let correctRate = ((correct / total) * 100).toFixed(1);
      return `${correctRate}%`;
    },
  },
});
