




































































































































import Vue from 'vue';
import { ImageLabel, ImageTestSet, TestResult } from '@/views/types';
import axios from 'axios';
import { mapMutations } from 'vuex';

interface TestResultWithRate extends TestResult {
  rate?: number;
}

interface ImageTestSetWithRate extends ImageTestSet {
  _id: string;
  topic: string;
  createDate: Date;
  images: Array<string>;
  labels: Array<ImageLabel>;
  testResults: Array<TestResultWithRate>;
}

export default Vue.extend({
  name: 'EditTestSet',
  components: {},
  data() {
    let imageTestSets = Array<ImageTestSet>();
    let targetImageTestSet: ImageTestSetWithRate = {
      _id: '',
      topic: '',
      createDate: new Date(),
      images: Array<string>(),
      labels: Array<ImageLabel>(),
      testResults: Array<TestResultWithRate>(),
    };
    const llmOptions = [
      { text: 'ChatGPT 4-o', value: 'gpt-4o' },
      { text: 'ChatGPT 4-o-mini', value: 'gpt-4o-mini' },
    ];

    const columns = [
      {
        key: 'index',
        label: '編號',
      },
      {
        key: 'model',
        label: '模型',
      },
      {
        key: 'question',
        label: '問題',
      },
      {
        key: 'keyword',
        label: '觸發關鍵字',
      },
      {
        key: 'rate',
        label: '準確率',
        formatter: (value: number) => {
          return `${(value * 100).toFixed(2)}%`;
        },
      },
    ];
    return {
      imageTestSets,
      targetImageTestSet,
      columns,
      currentPage: 1,
      llmOptions,
      form: {
        model: 'gpt-4o-mini',
        question: '',
        keyword: '',
      },
      testResultIndex: 0,
    };
  },
  computed: {
    myRows() {
      if (this.targetImageTestSet.testResults)
        return this.targetImageTestSet.testResults;

      return [];
    },
    testResultOptions() {
      if (!this.targetImageTestSet.testResults) return [];

      return this.targetImageTestSet.testResults.map(
        (testResult: any, index: number) => ({
          text: `測試結果 ${index + 1}`,
          value: index,
        }),
      );
    },
  },
  watch: {
    targetImageTestSet() {
      this.initLabels();
    },
    testResultIndex() {
      this.form.keyword =
        this.targetImageTestSet.testResults[this.testResultIndex].keyword;
    },
  },
  async created() {
    await this.getImageTestSets();
  },
  methods: {
    ...mapMutations(['setLoading']),
    async getImageTestSets() {
      try {
        const res = await axios.get('/ImageTestSet');
        if (res.status === 200) {
          this.imageTestSets = res.data;
          if (this.imageTestSets.length > 0) {
            this.testResultIndex %= this.imageTestSets.length;
            this.assignTargetImageTestSet(this.testResultIndex);
          } else {
            this.targetImageTestSet = {
              _id: '',
              topic: '',
              createDate: new Date(),
              images: Array<string>(),
              labels: Array<ImageLabel>(),
              testResults: Array<TestResult>(),
            };
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    assignTargetImageTestSet(index: number) {
      this.targetImageTestSet = this.imageTestSets[index];
      this.initLabels();
      if (this.targetImageTestSet.testResults.length > 0) {
        //this.form.keyword = this.targetImageTestSet.testResults[index].keyword;
        // Update test result rate
        for (let i = 0; i < this.targetImageTestSet.testResults.length; i++) {
          let correct = 0;
          let testResult = this.targetImageTestSet.testResults[i];
          for (let j = 0; j < this.targetImageTestSet.labels.length; j++) {
            let trigger = this.targetImageTestSet.labels[j].label === '有';
            if (
              this.isTestCorrect(
                trigger,
                testResult.response[j].label,
                testResult.keyword,
              )
            ) {
              correct++;
            }
          }
          this.targetImageTestSet.testResults[i].rate =
            correct / this.targetImageTestSet.labels.length;
        }
      }
    },
    initLabels() {
      if (this.targetImageTestSet.labels.length == 0) {
        this.targetImageTestSet.labels = this.targetImageTestSet.images.map(
          image => {
            return {
              id: image,
              label: '',
            };
          },
        );
      }
    },
    getImageUrl(id: string) {
      if (!id) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${id}`;
    },
    async deleteTest() {
      try {
        const res = await axios.delete(
          `/ImageTestSet/${this.targetImageTestSet._id}`,
          this.form,
        );
        if (res.status === 200) {
          await this.$bvModal.msgBoxOk('刪除成功');
          this.$emit('reload');
          await this.getImageTestSets();
        }
      } catch (e) {
        console.error(e);
        await this.$bvModal.msgBoxOk('刪除失敗');
      }
    },
    async deleteTestResult() {
      try {
        const url = `/ImageTestSet/${this.targetImageTestSet._id}/TestResult/${this.testResultIndex}`;
        const res = await axios.delete(url);
        if (res.status === 200) {
          await this.$bvModal.msgBoxOk('刪除成功');
          this.$emit('reload');
          await this.getImageTestSets();
        }
      } catch (e) {
        console.error(e);
        await this.$bvModal.msgBoxOk('刪除失敗');
      }
    },
    async updateTestResultKeyword() {
      try {
        const url = `/ImageTestSet/${this.targetImageTestSet._id}/TestResult/${this.testResultIndex}/keyword`;
        const res = await axios.post(url, { keyword: this.form.keyword });
        if (res.status === 200) {
          await this.$bvModal.msgBoxOk('更新成功');
          this.$emit('reload');
          await this.getImageTestSets();
        }
      } catch (e) {
        console.error(e);
        await this.$bvModal.msgBoxOk('更新失敗');
      }
    },
    isTestCorrect(
      label: boolean,
      response: string,
      keywordStr?: string,
    ): boolean {
      let keywords = keywordStr ? keywordStr.split(',') : [];
      let event = false;
      for (let keyword of keywords) {
        if (response.indexOf(keyword) !== -1) {
          event = true;
          break;
        }
      }
      if (label && event) return true;

      return !label && !event;
    },
  },
});
