var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":"","title":"影像辨識測試"}},[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":"1. 建立影像集","active":""}},[_c('image-grouping',{key:_vm.key0,on:{"reload":function($event){_vm.key1++;
          _vm.key2++;
          _vm.key3++;}}})],1),_c('b-tab',{attrs:{"title":"2. 標記結果"}},[_c('mark-image-label',{key:_vm.key1,on:{"reload":function($event){_vm.key0++;
          _vm.key2++;
          _vm.key3++;}}})],1),_c('b-tab',{attrs:{"title":"3. 執行測試"}},[_c('execute-test',{key:_vm.key2,on:{"reload":function($event){_vm.key0++;
          _vm.key1++;
          _vm.key3++;}}})],1),_c('b-tab',{attrs:{"title":"4. 影像集編輯"}},[_c('edit-test-set',{key:_vm.key3,on:{"reload":function($event){_vm.key0++;
          _vm.key1++;
          _vm.key2++;}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }