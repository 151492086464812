































































































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
import { mapMutations } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import { Camera, ImageLabel, ImageTestSet, TestResult } from '@/views/types';

const Ripple = require('vue-ripple-directive');

export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];

    const columns = [
      {
        key: 'selection',
        label: '選取',
      },
      {
        key: 'content',
        label: '圖片(點擊放大)',
      },
      {
        key: 'cameraId',
        label: '相機',
        formatter: (val: string) => {
          let camera = this.cameraList.find((c: Camera) => c._id === val);
          return camera ? camera.name : '';
        },
      },
      {
        key: 'createDate',
        label: '建立日期',
        sortable: true,
        formatter: (val: number) => {
          return moment(val).format('lll');
        },
      },
    ];
    let groupingForm: ImageTestSet = {
      _id: '測試影像集1',
      topic: '',
      createDate: new Date(),
      images: Array<string>(),
      labels: Array<ImageLabel>(),
      testResults: Array<TestResult>(),
    };

    return {
      columns,
      form: {
        topic: 'all',
        cameras: ['Test'],
        range,
      },
      groupingForm,
      topics: [
        { val: 'all', txt: '全部' },
        { val: '車斗覆蓋-帆布覆蓋', txt: '車斗覆蓋-帆布覆蓋' },
        { val: '車斗覆蓋-帆布下拉', txt: '車斗覆蓋-帆布下拉' },
        { val: '車斗覆蓋-帆布有沒有破損', txt: '車斗覆蓋-帆布有沒有破損' },
        { val: '露天燃燒', txt: '露天燃燒' },
        { val: '河川揚塵', txt: '河川揚塵' },
        { val: '煙囪排放', txt: '煙囪排放' },
        { val: '工地揚塵', txt: '工地揚塵' },
        { val: '工廠逸散', txt: '工廠逸散' },
      ],
      cameraList: Array<Camera>(),
      display: false,
      rows: Array<any>(),
      currentPage: 1,
    };
  },
  computed: {
    canBuildGroup(): boolean {
      return (
        this.groupingForm._id.length > 0 && this.groupingForm.images.length > 0
      );
    },
    topicsExcludeAll() {
      return this.topics.filter(t => t.val !== 'all');
    },
  },
  watch: {},
  async mounted() {
    await this.getCameraList();
  },
  methods: {
    ...mapMutations(['setLoading']),
    async query() {
      this.setLoading({ loading: true });
      try {
        this.display = true;
        this.rows = [];
        const cameras = this.form.cameras.join(':');
        const url = `/ImageQuery/${cameras}/${this.form.topic}/${this.form.range[0]}/${this.form.range[1]}`;

        const ret = await axios.get(url);
        this.rows = ret.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.setLoading({ loading: false });
      }
    },
    async getCameraList() {
      const res = await axios.get('/Cameras');
      if (res.status === 200) {
        this.cameraList = res.data;
      }
    },
    getImageUrl(id: string) {
      if (!id) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${id}`;
    },
    selectAll() {
      this.groupingForm.images.splice(0, this.groupingForm.images.length);
      this.rows.forEach((r: any) => {
        this.groupingForm.images.push(r._id);
      });
    },
    clearAll() {
      this.groupingForm.images.splice(0, this.groupingForm.images.length);
    },
    async buildTestSet() {
      try {
        const url = '/ImageTestSet';
        const ret = await axios.post(url, this.groupingForm);
        if (ret.status === 200) {
          await this.$bvModal.msgBoxOk('建立成功');
          this.$emit('reload');
        }
      } catch (e) {
        console.error(e);
        await this.$bvModal.msgBoxOk('建立失敗');
      } finally {
      }
    },
  },
});
