




































































import Vue from 'vue';
import { ImageLabel, ImageTestSet, TestResult } from '@/views/types';
import axios from 'axios';
export default Vue.extend({
  name: 'MarkImageLabel',
  components: {},
  data() {
    let imageTestSets = Array<ImageTestSet>();
    let targetImageTestSet: ImageTestSet = {
      _id: '',
      topic: '',
      createDate: new Date(),
      images: Array<string>(),
      labels: Array<ImageLabel>(),
      testResults: Array<TestResult>(),
    };

    const columns = [
      {
        key: 'id',
        label: '圖片',
      },
      {
        key: 'label',
        label: '標記',
      },
    ];
    const labels = ['有', '無'];
    return {
      imageTestSets,
      targetImageTestSet,
      columns,
      currentPage: 1,
      labels,
    };
  },
  computed: {},
  watch: {
    targetImageTestSet() {
      this.initLabels();
    },
  },
  async created() {
    await this.getImageTestSets();
  },
  methods: {
    async getImageTestSets() {
      try {
        const res = await axios.get('/ImageTestSet');
        if (res.status === 200) {
          this.imageTestSets = res.data;
          this.assignTargetImageTestSet(0);
        }
      } catch (e) {
        console.error(e);
      }
    },
    assignTargetImageTestSet(index: number) {
      this.targetImageTestSet = this.imageTestSets[index];
      this.initLabels();
    },
    initLabels() {
      if (this.targetImageTestSet.labels.length == 0) {
        this.targetImageTestSet.labels = this.targetImageTestSet.images.map(
          image => {
            return {
              id: image,
              label: '',
            };
          },
        );
      }
    },
    getImageUrl(id: string) {
      if (!id) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${id}`;
    },
    async updateImageLabel() {
      try {
        const res = await axios.post(
          `/ImageTestSet/${this.targetImageTestSet._id}/Labels`,
          this.targetImageTestSet.labels,
        );
        if (res.status === 200) {
          await this.$bvModal.msgBoxOk('更新成功');
          this.$emit('reload');
        }
      } catch (e) {
        console.error(e);
        await this.$bvModal.msgBoxOk('更新失敗');
      }
    },
  },
});
