





















































import Vue from 'vue';
import ImageGrouping from '@/views/ImageGrouping.vue';
import MarkImageLabel from '@/views/MarkImageLabel.vue';
import ExecuteTest from '@/views/ExecuteTest.vue';
import EditTestSet from '@/views/EditTestSet.vue';

export default Vue.extend({
  name: 'ImageRecognitionTest',
  components: {
    MarkImageLabel,
    ImageGrouping,
    ExecuteTest,
    EditTestSet,
  },
  data() {
    return {
      key0: 0,
      key1: 1,
      key2: 2,
      key3: 3,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
});
